@mixin text-shadow($string: 0 1px 3px rgba(0, 0, 0, 0.25)){
	text-shadow: $string;
}
@mixin box-shadow($string){
	-webkit-box-shadow: $string;
	-moz-box-shadow:    $string;
	box-shadow:         $string;
}
@mixin drop-shadow($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25){
	-webkit-box-shadow:	$x $y $blur $spread rgba(0, 0, 0, $alpha);
	-moz-box-shadow:	$x $y $blur $spread rgba(0, 0, 0, $alpha);
	box-shadow:		$x $y $blur $spread rgba(0, 0, 0, $alpha);
}
@mixin inner-shadow($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25){
	-webkit-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
	-moz-box-shadow:    inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
	box-shadow:         inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
}

@mixin box-sizing($type: border-box){
	-webkit-box-sizing: $type;
	-moz-box-sizing:    $type;
	box-sizing:         $type;
}

@mixin border-radius($radius: 5px){
	-webkit-border-radius: $radius;
	-moz-border-radius:    $radius;
	-ms-border-radius:     $radius;
    -o-border-radius:      $radius;
	border-radius:         $radius;

	-moz-background-clip:    padding;
	-webkit-background-clip: padding-box;
	background-clip:         padding-box;
}
@mixin border-radiuses($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0){
	-webkit-border-top-right-radius:    $topright;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-bottom-left-radius:  $bottomleft;
	-webkit-border-top-left-radius:     $topleft;

	-moz-border-radius-topright:        $topright;
	-moz-border-radius-bottomright:     $bottomright;
	-moz-border-radius-bottomleft:      $bottomleft;
	-moz-border-radius-topleft:         $topleft;

	border-top-right-radius:            $topright;
	border-bottom-right-radius:         $bottomright;
	border-bottom-left-radius:          $bottomleft;
	border-top-left-radius:             $topleft;

	-moz-background-clip:    padding;
	-webkit-background-clip: padding-box;
	background-clip:         padding-box;
}

@mixin opacity($opacity: 0.5){
	-webkit-opacity: 	$opacity;
	-moz-opacity: 		$opacity;
	opacity: 		$opacity;
}

@mixin gradient($startColor: #eee, $endColor: white){
	background-color: $startColor;
	background: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
	background: -webkit-linear-gradient(top, $startColor, $endColor);
	background: -moz-linear-gradient(top, $startColor, $endColor);
	background: -ms-linear-gradient(top, $startColor, $endColor);
	background: -o-linear-gradient(top, $startColor, $endColor);
}
@mixin horizontal-gradient($startColor: #eee, $endColor: white){
 	background-color: $startColor;
	background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
	background-image: -webkit-linear-gradient(left, $startColor, $endColor);
	background-image: -moz-linear-gradient(left, $startColor, $endColor);
	background-image: -ms-linear-gradient(left, $startColor, $endColor);
	background-image: -o-linear-gradient(left, $startColor, $endColor);
}

@mixin animation($name, $duration: 300ms, $delay: 0, $ease: ease){
	-webkit-animation: $name $duration $delay $ease;
	-moz-animation:    $name $duration $delay $ease;
	-ms-animation:     $name $duration $delay $ease;
}

@mixin transition($transition){
	-webkit-transition: $transition;
	-moz-transition:    $transition;
	-ms-transition:     $transition;
	-o-transition:      $transition;
}
@mixin transform($string){
	-webkit-transform: $string;
	-moz-transform: 	 $string;
	-ms-transform: 		 $string;
	-o-transform: 		 $string;
}
@mixin scale($factor){
	-webkit-transform: scale($factor);
	-moz-transform: 	 scale($factor);
	-ms-transform: 		 scale($factor);
	-o-transform: 		 scale($factor);
}
@mixin rotate($deg){
	-webkit-transform: rotate($deg);
	-moz-transform: 	 rotate($deg);
	-ms-transform: 		 rotate($deg);
	-o-transform: 		 rotate($deg);
}
@mixin skew($deg, $deg2){
	-webkit-transform:       skew($deg, $deg2);
	-moz-transform: 	 skew($deg, $deg2);
	-ms-transform: 		 skew($deg, $deg2);
	-o-transform: 		 skew($deg, $deg2);
}
@mixin translate($x, $y:0){
	-webkit-transform:       translate($x, $y);
	-moz-transform: 	 translate($x, $y);
	-ms-transform: 		 translate($x, $y);
	-o-transform: 		 translate($x, $y);
}
@mixin translate3d($x, $y: 0, $z: 0){
	-webkit-transform:       translate3d($x, $y, $z);
	-moz-transform: 	 translate3d($x, $y, $z);
	-ms-transform: 		 translate3d($x, $y, $z);
	-o-transform: 		 translate3d($x, $y, $z);
}
@mixin perspective($value: 1000){
	-webkit-perspective: 	$value;
	-moz-perspective: 	$value;
	-ms-perspective: 	$value;
	perspective: 		$value;
}
@mixin transform-origin($x:center, $y:center){
	-webkit-transform-origin: $x $y;
	-moz-transform-origin:    $x $y;
	-ms-transform-origin:     $x $y;
	-o-transform-origin:      $x $y;
}

@mixin reset-box-sizing($size:content-box){
  &,
  *,
  *:before,
  *:after {
    @include box-sizing($size);
  }
}

@mixin truncate($max-width: 250px){
  max-width: $max-width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin background-size($string: contain){
    -webkit-background-size: $string;
    -moz-background-size: $string;
    -o-background-size: $string;
    background-size: $string;
}

// retina.less
// A helper mixin for applying high-resolution background images (http://www.retinajs.com)

$highdpi: #{"(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)"};

@mixin background-image-retina($file, $type, $width, $height) {
  background-image: url($file + '.' + $type);
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-moz-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx){
    & {
      background-image: url($file + '@2x.' + $type);
      -webkit-background-size: $width $height;
      -moz-background-size: $width $height;
      -o-background-size: $width $height;
      background-size: $width $height;
    }
  }
}
